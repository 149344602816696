const initState = {
  photoList: [],
  commentList: []
};

const photoReducer = (state = initState, action) => {
  switch (action.type) {
    case "LIST_PHOTO_SUCCESS":
      return {
        ...state,
        photoList: action.query,
        commentList: action.comments
      };

    case "LIST_PHOTO_ERR":
      return {
        ...state,
        error: action.err
      };

    case "SHARE_START":
        return { 
         ...state,
          photoList: action.sharingPhotoList
       }

      case "SHARE_COMPLETE":
        return {
          ...state,
          photoList: action.sharedPhotoList,
        };

    case "DELETE_PHOTO":
      const photoId = action.data;
      const updatedPhotoList = state.photoList.filter(function(photoObj) {
        return photoObj.photoId !== photoId;
      });
      return { ...state, photoList: updatedPhotoList };

    default:
      return state;
  }
};

export default photoReducer;
