import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {  Input, Icon, Button, Modal } from "antd";

import { getAccount } from "../actions/accountActions";
import { getBusiness } from "../actions/businessActions";
import { getPhotos, deletePhoto, sharePhoto } from "../actions/photoActions";
import Loading from "./Loading";

const { TextArea } = Input;
const { confirm } = Modal;

const Wrapper = styled.div`
  padding: 60px 10px;
  max-width: 1024px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding-bottom: 70px;
  }
`;

const ContainerBox = styled.div`
  background: #f9f9f9;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  opacity: ${props => props.isShared || props.isShareable===false ? '.5' : '1'};
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const PhotoContainer = styled.div`
  width: 30%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Photo = styled.img`
  max-width: 100%;
`;

const CustomerCommentBox = styled.div`
  width: 40%;
  padding: 15px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ShareBox = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 13px;

  @media (max-width: 600px) {
    flex-direction: row;
    margin: 0 auto;
    width: 95%;
    flex-wrap: nowrap;
  }
`;

const ShareButton = styled.div`
  margin: 10px;
  width: 60%;

  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
`;

const SharedText = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding-left: 6px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
`;

const SharedComment = styled.blockquote`
  padding: 12px;
  background: rgba(0,0,0,.1);
  color: #000;
}
`

class PhotosPage extends Component {
  state = {
    loading: []
  };

  componentDidMount() {
    Promise.all([this.props.getAccount()]).then(result => {
      Promise.all([this.props.getPhotos()]).then(allphotos => {});
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  showConfirm = photoId => {
    let self = this;
    confirm({
      title: "Are you sure you want to delete this photo?",
      onOk() {
        self.props.deletePhoto(photoId);
      },
      onCancel() {}
    });
  };

  sharePhotoToSlack = (e, photoId) => {
    let loading = this.state.loading;
    loading[photoId] = true;
    this.setState({
      loading: loading
    });
    this.props.sharePhoto(photoId);
  };
  render() {
    if (!this.props.photo.photoList ) {
      return <Loading />;
    }
    return (
      <Wrapper>
        {this.props.photo.photoList.map(photo => (
          <div key={photo.photoId}>
            <h3>{photo.created}</h3>
            <ContainerBox isShareable={photo.shareable} isShared={photo.shared}>
              <PhotoContainer>
                <Photo src={photo.url} key={photo.photoId} alt="" />
              </PhotoContainer>
              <CustomerCommentBox>
                <h3>{photo.customerFn} </h3>
                {!photo.shared && photo.shareable && (
                  <TextArea
                    id={photo.photoId}
                    onChange={this.handleChange}
                    placeholder="Insert Comments"
                  />
                )}
                {!photo.shared && !photo.shareable && <div></div>}
                {photo.shared && <SharedComment>{photo.comment}</SharedComment>}
              </CustomerCommentBox>
              <ShareBox>
                <ShareButton>
                  {!photo.sharing && !photo.shared && photo.shareable && (
                    <Button
                      type="primary"
                      block
                      // href={photo.shareableLink}
                      //   loading={this.state.loading[photo.photoId] || false}
                      onClick={e => this.sharePhotoToSlack(e, photo.photoId)}
                    >
                      Share
                    </Button>
                  )}
                  {photo.sharing && !photo.shared && (
                    <SharedText> Sharing...</SharedText>
                  )}

                  {photo.shared && (
                    <SharedText>
                      <Icon type="check" />
                      <span>Added to Facebook queue</span>
                    </SharedText>
                  )}
                  {!photo.shared && !photo.shareable && (
                    <SharedText>
                      <Icon type="lock" />{" "}
                      <span> Sharing permission not granted</span>
                    </SharedText>
                  )}
                </ShareButton>
                <ShareButton>
                  <Button
                    onClick={() => this.showConfirm(photo.photoId)}
                    type="danger"
                    block
                    //  loading={this.state.loading[photo.photoId] || false}
                  >
                    Delete
                  </Button>
                </ShareButton>
              </ShareBox>
            </ContainerBox>
          </div>
        ))}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.account,
    auth: state.firebase.auth,
    business: state.business,
    photo: state.photo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBusiness: () => dispatch(getBusiness()),
    getAccount: () => dispatch(getAccount()),
    getPhotos: () => dispatch(getPhotos()),
    deletePhoto: photoId => dispatch(deletePhoto(photoId)),
    sharePhoto: photoId => dispatch(sharePhoto(photoId)),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotosPage);
