export const getPhotos = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log("Starting getPhoto action");
    const firebase = getFirebase();
    const db = firebase.firestore();
    const business = getState().account.business;
    const query = [];
    let promises = [];
    const getImageUrl = "https://autoreviews.web.app/photo/";
    const fetchCustomerByCustomerId = customerId => {
      return db
        .collection("customers")
        .doc(customerId)
        .get();
    };

    db.collection("photos")
      .where("business_id", "==", business)
      .where("deleted", "==", false)
      .orderBy("created", "desc")
      .get()
      .then(snapshot => {
        return new Promise((resolve, reject) => {
          snapshot.forEach(photo => {
            let photoObj = {};
            promises.push(
              fetchCustomerByCustomerId(photo.data().customer_id).then(
                result => {
                  photoObj = {
                    photoId: photo.id,
                    customerId: result.id,
                    customerFn: result.data().first_name,
                    customerLn: result.data().last_name,
                    shareable: photo.data().shareable,
                    comment: photo.data().comment,
                    url: getImageUrl + photo.data().purchase,
                    created: formatDate(
                      toDateTime(photo.data().created.seconds)
                    ),
                    shared: photo.data().shared || false
                    //     shareableLink: "https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(getImageUrl+photo.data().purchase)+"&amp;src=sdkpreparse"
                  };
                  query.push(photoObj);
                }
              )
            );
          });
          resolve();
        });
      })
      .then(() => {
        Promise.all(promises).then(() => {
          dispatch({ type: "LIST_PHOTO_SUCCESS", query });
        });
      });
  };
};

export const deletePhoto = photoId => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const db = firebase.firestore();
    db.collection("photos")
      .doc(photoId)
      .update({ deleted: true })
      .then(() => {
        dispatch({ type: "DELETE_PHOTO", data: photoId });
      });
  };
};

function toDateTime(secs) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}

function formatDate(date) {
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  var weekday = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
  var dateValue = date.getDate();
  var day = date.getDay();
  var monthIndex = date.getMonth();
  //var year = date.getFullYear();

  return weekday[day] + " " + dateValue + " " + monthNames[monthIndex];
}

export const sharePhoto = photoId => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const db = firebase.firestore();
    const commentData = document.getElementById(photoId).value;
    const photoList = getState().photo.photoList;
    const retrievedObject = photoList.find(photo => photo.photoId === photoId);
    const sharingPhotoList = photoList.map(photo =>
      photo.photoId === photoId
        ? Object.assign({}, photo, { ...retrievedObject, sharing: true })
        : photo
    );
    dispatch({ type: "SHARE_START", sharingPhotoList });

    db.collection("photos")
      .doc(photoId)
      .update({
        comment: commentData,
        shared: true,
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          const sharedPhotoList = photoList.map(photo =>
            photo.photoId === photoId
              ? Object.assign({}, photo, {
                  ...retrievedObject,
                  sharing: false,
                  shared: true,
                  comment: commentData
                })
              : photo
          );
          resolve(dispatch({ type: "SHARE_COMPLETE", sharedPhotoList }));
        });
      })
      .then(() => {
        fetch('https://us-central1-autoreviews.cloudfunctions.net/api/passToSlack/'+photoId)
      });
  };
};
